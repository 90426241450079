import React, { Component } from 'react';
import Container from '../container';
import Row from '../row';
import Col from '../col';
import Title from '../title';
import Section from '../section';
import Gallery from '../gallery';
import Faq from '../faq';
import Options from '../main-icon';
import Gest from '../gestao';
import List from '../../components/list';
import Image2 from '../../images/image-2.png';
import api from '../../api';
import './main.scss';

class Main extends Component {
  constructor(props) {
    super(props);

    this.state = {
      current: 0,
      canChange: true,
      barra: [],
      info: [],
      gallery: [],
      faq: []
    };

    this.changeGest = this.changeGest.bind(this);
    this.autoPlay = this.autoPlay.bind(this);
    this.timeOut = null;
  }

  componentDidMount() {
    api.get('/barra').then(res => {
      this.setState({
        barra: res.data
      });
    });

    api.get('/info').then(res => {
      this.setState({
        info: res.data
      });
    });

    api.get('/imagens').then(res => {
      this.setState({
        gallery: res.data
      });
    });

    api.get('/faq').then(res => {
      this.setState({
        faq: res.data
      });
    });
  }

  autoPlay() {
    if (this.state.canChange) {
      this.timeOut = setTimeout(() => {
        this.setState(state => ({
          current: (state.current + 1) % this.state.info.length
        }));
      }, 2000);
    } else {
      clearTimeout(this.timeOut);
    }
  }

  changeGest(id) {
    this.setState({ current: id, canChange: false });
  }

  render() {
    return (
      <main className="main">
        <Section id="section-1">
          <Container>
            <Row center reverse>
              <Col size={6}>
                <Options
                  data={this.state.info}
                  onClick={this.changeGest}
                  current={this.state.current}
                />
              </Col>
              <Col size={6}>
                <Gest
                  data={this.state.info}
                  current={this.state.current}
                  changeMude={this.autoPlay}
                />
              </Col>
            </Row>
          </Container>
        </Section>
        <Section id="section-2">
          <Container>
            <Row center>
              <Col size={6} style={{ textAlign: 'center' }}>
                <img src={Image2} alt="Telas" />
              </Col>
              <Col size={6}>
                <Title style={{ fontSize: 28, textAlign: 'center' }}>
                  Você também terá direito a
                </Title>
                <List data={this.state.barra} />
              </Col>
            </Row>
          </Container>
        </Section>
        <Section id="section-3">
          <Container>
            <Gallery const images={this.state.gallery} />
          </Container>
        </Section>
        <Section id="section-4">
          <Container>
            <Row>
              <Col>
                <Title style={{ textAlign: 'center' }}>
                  Principais perguntas
                </Title>
                <Faq questions={this.state.faq} />
              </Col>
            </Row>
          </Container>
        </Section>
      </main>
    );
  }
}

export default Main;
