import React from 'react';
import PropTypes from 'prop-types';
import './social.scss';

const info = {
  facebook: {
    url: 'https://facebook.com/',
    title: 'Facebook',
    class: 'facebook'
  },

  instagram: {
    url: 'https://instagram.com/',
    title: 'Instagram',
    class: 'instagram'
  },

  medium: {
    url: 'https://medium.com/@',
    title: 'Medium',
    class: 'medium'
  },
  youtube: {
    url: 'https://youtube.com/channel/',
    title: 'YouTube',
    class: ''
  },
  twitter: {
    url: 'https://twitter.com/',
    title: 'Twitter',
    class: 'twitter'
  },
  linkedin: {
    url: 'https://www.linkedin.com/company/',
    title: 'LinkedIn',
    class: 'linkedin'
  },

  spotify: {
    url: 'https://open.spotify.com/user/',
    title: 'Spotify',
    class: 'spotify'
  }
};

const Social = props => {
  const dataSocial = info[props.name];

  return (
    <a
      className={`social ${props.name}`}
      href={`${dataSocial.url}${props.children}`}
      target="_blank"
      rel="noopener noreferrer"
      title={dataSocial.title}
    >
      <span>{dataSocial.title}</span>
    </a>
  );
};

Social.propTypes = {
  name: PropTypes.oneOf([
    'facebook',
    'instagram',
    'medium',
    'twitter',
    'youtube',
    'linkedin',
    'spotify'
  ]),
  children: PropTypes.string
};

export default Social;
